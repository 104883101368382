import { Action, AnyAction, combineReducers } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { useSelector, TypedUseSelectorHook } from "react-redux";

import auth from "./auth/reducer";

const rootReducer = combineReducers({ auth });
export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
