import React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { Container } from "@material-ui/core";

import { Button } from "../../_lib";
import { Footer } from "../../components";
import Hero from "./Hero";
import SectionStep from "./components/SectionStep";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#060220",
      minHeight: "100vh",
    },
    signUpBtnContainer: {
      padding: theme.spacing(20, 0),
      display: "flex",
      justifyContent: "center",
    },
    signUpBtn: {
      width: 250,
    },
  })
);

const HowItWorks: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hero />
      <Container>
        {steps.map((step) => (
          <SectionStep key={step.stepNum} {...step} />
        ))}
        <div className={classes.signUpBtnContainer}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.signUpBtn}
            // @ts-ignore
            component="a"
            href="https://app.playctl.net/"
          >
            Sign up now!
          </Button>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default React.memo(HowItWorks);

const steps: SectionStepItem[] = [
  {
    stepNum: 1,
    title: "Onboarding",
    subtitle:
      "Signup free or signup using friend’s referral code, using email or social media sign up.",
    substeps: [
      { label: "Sign in", img: "" },
      { label: "Sign up", img: "" },
      { label: "Recovery", img: "" },
      { label: "Social Sign up", img: "" },
      { label: "Dashboard", img: "" },
    ],
  },
  {
    stepNum: 2,
    title: "Join Leagues",
    subtitle:
      "Find the suitable leagues to keep track of your networth and do buy sell activities on the platform. Pay and get your tickets.",
    substeps: [
      { label: "Upcoming Leagues", img: "" },
      { label: "Strategies", img: "" },
      { label: "Ticket Price", img: "" },
      { label: "Confirm", img: "" },
      { label: "Get Tickets", img: "" },
    ],
  },
  {
    stepNum: 3,
    title: "Play Leagues",
    subtitle:
      "Start playing after league start time with the virtual money you have got, try to increase the net-worth by trading / buying and selling crypto currencies. More networth higher the rank.",
    substeps: [
      { label: "Upcoming Leagues", img: "" },
      { label: "Strategies", img: "" },
      { label: "Ticket Price", img: "" },
      { label: "Confirm", img: "" },
      { label: "Get Tickets", img: "" },
    ],
  },
  {
    stepNum: 4,
    title: "Leaderboard",
    subtitle:
      "After exact 48 hours once the countdown timer stops, system will generate the leader board and winner.",
    substeps: [
      { label: "Upcoming Leagues", img: "" },
      { label: "Strategies", img: "" },
      { label: "Ticket Price", img: "" },
      { label: "Confirm", img: "" },
      { label: "Get Tickets", img: "" },
    ],
  },
  {
    stepNum: 5,
    title: "Rewards",
    subtitle:
      "Winners will get the direct Cash rewards credited to their crypto wallet or supported cash out options.",
    substeps: [
      { label: "Upcoming Leagues", img: "" },
      { label: "Strategies", img: "" },
      { label: "Ticket Price", img: "" },
      { label: "Confirm", img: "" },
      { label: "Get Tickets", img: "" },
    ],
  },
];
