import { applyMiddleware, compose, createStore, StoreEnhancerStoreCreator } from 'redux';
import thunkMiddleware from 'redux-thunk';

import rootReducer from '../rootReducer';

export default function configureStore(preloadedState: any) {
  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose<StoreEnhancerStoreCreator>(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}
