import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  Container,
  Paper,
} from "@material-ui/core";

import { Button } from "../../_lib";
import { SectionTitle } from "../../components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(5, 0, 10, 0),
    },
    titleRoot: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(3),
    },
    sectionTitle: {
      "&:before": {
        content: '" "',
        width: "75%",
        left: 0,
        bottom: -20,
      },
      "&:after": {
        content: '" "',
        width: "50%",
        right: "-10%",
        bottom: 5,
        [theme.breakpoints.only("sm")]: { right: "-5%" },
        [theme.breakpoints.only("xs")]: { right: "5%" },
      },
    },
    captionPaper: {
      marginTop: theme.spacing(10),
      padding: theme.spacing(4, 6),
      borderRadius: 10,
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    captionText: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(34),
      letterSpacing: 0,
    },
    infoContainer: {
      padding: theme.spacing(10, 0),
    },
    appMarketBtnContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    btnRoot: { width: 250 },
  })
);

const HowReferralWorks: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SectionTitle
        rootClass={classes.titleRoot}
        className={classes.sectionTitle}
      >
        How multilevel referral works
      </SectionTitle>
      <Container>
        <Paper className={classes.captionPaper}>
          <Typography className={classes.captionText}>
            Eg: A invites B and B invites C and C invites D. When B spends USD
            100, A get 12.5% or USD 12.5 as a referral bonus. When C spends USD
            100 on the platform, B gets USD 12.5, and A gets USD 7.5 (2nd Level
            referral). When D spends USD 100 on the platform, C gets USD 12.5,
            and B gets USD 7.5 and A gets USD 5 (3rd Level referral)
          </Typography>
        </Paper>
        <Grid container className={classes.infoContainer} spacing={4}>
          {infoList.map((info) => (
            <InfoItem info={info} key={info.title} />
          ))}
        </Grid>
      </Container>
      <div className={classes.appMarketBtnContainer}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          classes={{ root: classes.btnRoot }}
        >
          Get your referral code
        </Button>
      </div>
    </div>
  );
};

export default React.memo(HowReferralWorks);

interface infoItem {
  title: string;
  subtitle: string;
  iconBgColor: "primary" | "secondary" | "error";
  level: 1 | 2 | 3;
}
const infoList: infoItem[] = [
  {
    title: "A refer to B",
    subtitle: "A gets 12.5% of the total purchase by B",
    iconBgColor: "primary",
    level: 1,
  },
  {
    title: "B refer to C",
    subtitle: "A also gets 7.5% of the total purchase by C",
    iconBgColor: "secondary",
    level: 2,
  },
  {
    title: "C refer to D",
    subtitle: "A also gets 5% of the total purchase by D",
    iconBgColor: "error",
    level: 3,
  },
];

const useInfoItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: "flex", justifyContent: "center" },
    leftRoot: { position: "relative", marginRight: theme.spacing(3) },
    imgBg: (props: InfoItemProps) => ({
      backgroundColor: theme.palette[props.info.iconBgColor].main,
      height: 80,
      width: 80,
      borderRadius: 40,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }),
    label: { fontSize: theme.typography.pxToRem(22), lineHeight: "27px" },
    value: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(30),
      lineHeight: "36px",
      letterSpacing: 0,
    },
  })
);

interface InfoItemProps {
  info: infoItem;
}
const InfoItem: React.FC<InfoItemProps> = (props) => {
  const { info } = props;
  const classes = useInfoItemStyles(props);

  return (
    <Grid item xs={12} sm={4}>
      <div className={classes.root}>
        <div className={classes.leftRoot}>
          <div className={classes.imgBg}>
            <Typography className={classes.label}>Level</Typography>
            <Typography className={classes.value}>{info.level}</Typography>
          </div>
        </div>
        <div>
          <Typography color="secondary" variant="h5">
            {info.title}
          </Typography>
          <Typography>{info.subtitle}</Typography>
        </div>
      </div>
    </Grid>
  );
};
