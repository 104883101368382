import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Container,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";
import clsx from "clsx";

import OvalGradient from "../../_lib/OvalGradient";
import { SectionTitle } from "../../components";

import img1 from "./_assets/Img-1.png";
import img2 from "./_assets/Img-2.png";
import img3 from "./_assets/Img-3.png";
import img4 from "./_assets/Img-4.jpeg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(16, 0),
      [theme.breakpoints.only("sm")]: { padding: theme.spacing(8, 0) },
      [theme.breakpoints.only("xs")]: { padding: theme.spacing(4, 0) },
    },
    imgGrid: {
      padding: theme.spacing(5),
      position: "relative",
      [theme.breakpoints.only("sm")]: { height: 440 },
      [theme.breakpoints.only("xs")]: { height: 240 },
    },
    img: {
      height: 262,
      width: 262,
      zIndex: 1,
      [theme.breakpoints.only("sm")]: { height: 200, width: 200 },
      [theme.breakpoints.only("xs")]: { height: 94, width: 94 },
    },
    img1: {
      position: "absolute",
      top: theme.spacing(-8),
      right: theme.spacing(4),
      [theme.breakpoints.down("sm")]: { right: "25%", top: 0 },
    },
    img2: {
      position: "absolute",
      bottom: theme.spacing(-8),
      [theme.breakpoints.down("sm")]: { left: "25%", bottom: "20%" },
    },
    img3: {
      height: 168,
      width: 168,
      borderRadius: 168 / 2,
      position: "absolute",
      left: 0,
      [theme.breakpoints.only("sm")]: { left: "30%", height: 90, width: 90 },
      [theme.breakpoints.only("xs")]: { left: "25%", height: 60, width: 60 },
    },
    circle: {
      height: 168,
      width: 168,
      borderRadius: 168 / 2,
      background: "white",
      boxShadow: "0 11px 35px #0000000D",
      position: "absolute",
      [theme.breakpoints.only("sm")]: { height: 90, width: 90 },
      [theme.breakpoints.only("xs")]: { height: 60, width: 60 },
    },
    img4: {
      position: "absolute",
      height: 168,
      width: 168,
      borderRadius: 168 / 2,
      right: theme.spacing(16),
      bottom: 0,
      [theme.breakpoints.down("sm")]: { right: "30%", bottom: "30%" },
      [theme.breakpoints.only("sm")]: { height: 90, width: 90 },
      [theme.breakpoints.only("xs")]: { height: 60, width: 60 },
    },
    circle3: {
      height: 98,
      width: 98,
      borderRadius: 98 / 2,
      left: theme.spacing(24),
      bottom: theme.spacing(-24),
      [theme.breakpoints.down("sm")]: {
        height: 50,
        width: 50,
        bottom: 0,
        left: "40%",
      },
      [theme.breakpoints.only("xs")]: { height: 35, width: 35 },
    },
    gradientCircle: {
      position: "absolute",
      bottom: theme.spacing(-16),
      left: theme.spacing(-3),
      [theme.breakpoints.down("sm")]: {
        width: 73,
        height: 71,
        left: "20%",
        bottom: "10%",
      },
      [theme.breakpoints.only("xs")]: { width: 47, height: 46 },
    },
    divider: {
      width: 28,
      backgroundColor: "#878F9D",
      marginRight: theme.spacing(2),
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      margin: theme.spacing(2, 0),
      fontSize: theme.typography.pxToRem(20),
    },
  })
);

const OurTeam: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} className={classes.imgGrid}>
            <img
              src={img1}
              alt="founder 1"
              className={clsx(classes.img, classes.img1)}
            />
            <img
              src={img2}
              alt="founder 2"
              className={clsx(classes.img, classes.img2)}
            />
            <img
              src={img3}
              alt="leader 1"
              className={clsx(classes.img, classes.img3)}
            />
            <img
              src={img4}
              alt="leader 2"
              className={clsx(classes.img, classes.img4)}
            />
            <div className={clsx(classes.circle, classes.circle3)} />
            <OvalGradient className={classes.gradientCircle} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center">
              <Divider className={classes.divider} />
              <Typography variant="h5" color="secondary">
                More about us
              </Typography>
            </Box>
            <SectionTitle color="error" rootClass={classes.title}>
              Our Team
            </SectionTitle>
            <Typography className={classes.subtitle}>
              We consider clarity of thought to be the single most important
              trait we look for in our colleagues. We think about the message we
              want to deliver before we speak, and know what is important and
              what is not for our learners.
            </Typography>
            <Typography className={classes.subtitle}>
              We are concise and articulate, putting effort into making things
              simple and easy to understand. Enjoy Risk Free Learning &amp;
              Crypto Trading Exposure !
            </Typography>
            <Typography color="primary" className={classes.subtitle}>
              READ MORE
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default React.memo(OurTeam);
