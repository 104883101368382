import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core";

import { HeroBase } from "../../components";
import { Button } from "../../_lib";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnRoot: {
      width: 250,
      backgroundColor: "#03004D",
      marginTop: theme.spacing(5),
    },
  })
);

const Hero: React.FC = () => {
  const classes = useStyles();

  const renderButtons = (
    <Button
      variant="contained"
      color="inherit"
      size="large"
      classes={{ root: classes.btnRoot }}
      // @ts-ignore
      component={Link}
      to="/faq"
    >
      Read our FAQs
    </Button>
  );

  return (
    <HeroBase
      title="Contact Us"
      subtitle="What can we help you with?"
      buttons={renderButtons}
    />
  );
};

export default React.memo(Hero);
