import * as types from "./types";
import callApi from "../../_utils/callApi";
import { getToken } from "./selectors";

export const getProfile =
  (): AppThunk<Promise<null | AnyObject>> => async (dispatch, getState) => {
    const token = getToken(getState());
    if (!token) return null;
    try {
      const [jsonResponse, error] = await callApi({
        endpoint: "v1/user/profile",
        token,
      });
      if (error) return jsonResponse;
      const { data } = jsonResponse.response;
      dispatch<types.AuthActionTypes>({
        type: types.RECEIVE_PROFILE_DATA,
        data,
      });
      return null;
    } catch (err) {
      return {
        msg: String(err),
      };
    }
  };

interface ISubmitFormData {
  firstName: string;
  lastName: string;
  contact: string;
  emailId: string;
  companyName: string;
  message: string;
}
export const submitEnquiryForm =
  (formData: ISubmitFormData): AppThunk<Promise<null | AnyObject>> =>
  async (dispatch, getState) => {
    const token = getToken(getState());
    if (!token) return null;
    try {
      const [jsonResponse, error] = await callApi({
        endpoint: "v1/enquiry/info",
        method: "POST",
        body: formData,
        token,
      });
      if (error) return jsonResponse;
      // const { data } = jsonResponse.response;
      // dispatch<types.AuthActionTypes>({
      //   type: types.RECEIVE_PROFILE_DATA,
      //   data,
      // });
      return null;
    } catch (err) {
      return {
        msg: String(err),
      };
    }
  };
