import React from "react";
import { Typography } from "@material-ui/core";

interface ItemProps {
  question: string;
  answer: string;
}
const Item: React.FC<ItemProps> = (props) => {
  const { question, answer } = props;
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {question}
      </Typography>
      <Typography>{answer}</Typography>
    </div>
  );
};

export default React.memo(Item);
