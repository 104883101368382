import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";

import { Footer } from "../../components";
import Hero from "./Hero";
import WhatsReferralProgram from "./WhatsReferralProgram";
import HowReferralWorks from "./HowReferralWorks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#060220",
      minHeight: "100vh",
    },
  })
);

const ReferralProgram: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hero />
      <WhatsReferralProgram />
      <HowReferralWorks />
      <Footer />
    </div>
  );
};

export default ReferralProgram;
