import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  Container,
} from "@material-ui/core";

import { Button } from "../../_lib";
import { SectionTitle } from "../../components";
import { Apple, GooglePlay } from "../../assets/Icons";
import LearnTrade from "../../assets/Images/LearnTrade.svg";
import StockMarket from "../../assets/Images/StockMarket.svg";
import Trophy from "../../assets/Images/Trophy.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(8, 0),
    },
    sectionTitle: {
      "&:after": {
        content: '" "',
        width: "50%",
        right: "-10%",
        bottom: 5,
        [theme.breakpoints.only("xs")]: { width: "30%", right: "5%" },
      },
      "&:before": {
        content: '" "',
        width: "75%",
        left: 0,
        bottom: -20,
        [theme.breakpoints.only("xs")]: { left: "5%", width: "70%" },
      },
    },
    titleRoot: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(3),
    },
    infoContainer: {
      padding: theme.spacing(10, 0),
    },
    appMarketBtnContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    btnRoot: {
      width: 200,
      margin: theme.spacing(0, 2),
      [theme.breakpoints.only("xs")]: { margin: theme.spacing(2) },
    },
    leftBtn: { backgroundColor: theme.palette.error.main },
  })
);

const MainSection: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SectionTitle
        rootClass={classes.titleRoot}
        className={classes.sectionTitle}
      >
        Mock Trading with BIG Price
      </SectionTitle>
      <Container>
        <div className={classes.infoContainer}>
          <Grid container spacing={4}>
            {infoList.map((info) => (
              <InfoItem info={info} key={info.title} />
            ))}
          </Grid>
        </div>
      </Container>
      <div className={classes.appMarketBtnContainer}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          classes={{ root: classes.btnRoot }}
          className={classes.leftBtn}
          startIcon={<Apple />}
        >
          App Store
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          classes={{ root: classes.btnRoot }}
          startIcon={<GooglePlay />}
        >
          Play Store
        </Button>
      </div>
    </div>
  );
};

export default React.memo(MainSection);

interface infoItem {
  title: string;
  subtitle: string;
  icon: string;
  iconBgColor: string;
}
const infoList: infoItem[] = [
  {
    title: "Stock Market Game",
    subtitle: "Learn, Implement, Practice, and Repeat!",
    icon: StockMarket,
    iconBgColor: "#01C48D",
  },
  {
    title: "Learn to trade",
    subtitle: "Risk? What’s that? Experiment and Experience!",
    icon: LearnTrade,
    iconBgColor: "#5B53FF",
  },
  {
    title: "Get awarded",
    subtitle: "Get Smarter, Trade Better! PLAY, LEARN, AND WIN!",
    icon: Trophy,
    iconBgColor: "#FDBC2A",
  },
];

const useInfoItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: "flex", justifyContent: "center" },
    leftRoot: { position: "relative", marginRight: theme.spacing(3) },
    imgBg: (props: InfoItemProps) => ({
      backgroundColor: props.info.iconBgColor,
      height: 80,
      width: 80,
      borderRadius: 40,
    }),
    img: { position: "absolute", top: 0, left: 0 },
  })
);

interface InfoItemProps {
  info: infoItem;
}
const InfoItem: React.FC<InfoItemProps> = (props) => {
  const { info } = props;
  const classes = useInfoItemStyles(props);

  return (
    <Grid item xs={12} sm={4}>
      <div className={classes.root}>
        <div className={classes.leftRoot}>
          <div className={classes.imgBg} />
          <img src={info.icon} alt="icon" className={classes.img} />
        </div>
        <div>
          <Typography color="secondary" variant="h5">
            {info.title}
          </Typography>
          <Typography>{info.subtitle}</Typography>
        </div>
      </div>
    </Grid>
  );
};
