import { Reducer } from "redux";
import { produce } from "immer";
import jwtDecode from "jwt-decode";
// import { compareDesc } from "date-fns";

import * as types from "./types";

import { getAuthTokenFromCookie } from "../../_utils/cookie";

interface IDecodedToken {
  _id: string;
  email: string;
  role: "user" | "admin";
  iat: number;
}

const getLocalJwtToken = () => {
  const token = getAuthTokenFromCookie();
  if (!token) return null;
  const decoded = jwtDecode<IDecodedToken>(token);
  // console.log(decoded);
  // const expired = decoded.exp < Date.now() / 1000;
  // if (expired) return null;
  return { token, ...decoded };
};

interface AuthState {
  token: string | undefined;
  walletBTC: number | null;
  _id: string | null;
  name: string | null;
  email: string | null;
  refcode: string | null;
  profileImg: string | null;
  joiningDate: string | null;
}

const initialState: AuthState = {
  token: undefined,
  _id: null,
  walletBTC: null,
  name: null,
  email: null,
  refcode: null,
  profileImg: null,
  joiningDate: null,
  ...getLocalJwtToken(),
};

const auth: Reducer<AuthState, types.AuthActionTypes> = (
  state = initialState,
  action
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.RECEIVE_PROFILE_DATA:
        draft.walletBTC = action.data.wallet;
        draft.name = action.data.name;
        draft.email = action.data.email;
        draft.refcode = action.data.refcode;
        draft.profileImg =
          action.data.profileImg !== "NA" ? action.data.profileImg : null;
        draft.joiningDate = action.data.joineDate;
        break;
    }
  });

export default auth;
