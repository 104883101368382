import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Button as MUIButton,
  ButtonProps,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { borderRadius: 10 },
    containedSizeLarge: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    outlinedSizeLarge: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    label: {
      fontWeight: "normal",
      textTransform: "none",
      fontSize: theme.typography.pxToRem(18),
    },
  })
);

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const customClasses = useStyles();
    const { disableElevation = true, classes, ...otherProps } = props;

    return (
      <MUIButton
        ref={ref}
        disableElevation={disableElevation}
        classes={{
          ...classes,
          root: clsx(customClasses.root, classes?.root),
          label: clsx(customClasses.label, classes?.label),
          containedSizeLarge: clsx(
            customClasses.containedSizeLarge,
            classes?.containedSizeLarge
          ),
          outlinedSizeLarge: clsx(
            customClasses.outlinedSizeLarge,
            classes?.containedSizeLarge
          ),
        }}
        {...otherProps}
      />
    );
  }
);

export default React.memo(Button);
