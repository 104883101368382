import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Container,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

import OvalGradient from "../../_lib/OvalGradient";
import { SectionTitle } from "../../components";
import TeamMemberCard from "./components/TeamMemberCard";

import img3 from "./_assets/Img-3.png";
import img4 from "./_assets/Img-4.jpeg";

const Leaders: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <OvalGradient className={classes.gCircle1} />
        <OvalGradient className={classes.gCircle2} />
        <OvalGradient
          className={classes.gCircle3}
          gradientStart="#3BE4CEB4"
          gradientEnd="#130D4AAF"
        />
        {/* Title and Subtitle */}
        <Grid container spacing={4} justify="center">
          <Grid item xs={12} md={8} lg={7} xl={6}>
            <SectionTitle
              color="error"
              rootClass={classes.title}
              align="center"
            >
              Leaders
            </SectionTitle>
            <Typography className={classes.subtitle} align="center">
              It’s our basic fundamental job to make your experience more
              seamless and easy to learn with opportunities of big rewards.
            </Typography>
          </Grid>
        </Grid>
        {/* Leaders Cards */}
        <Grid container spacing={4} justify="center">
          {leaders.map((leader) => (
            <Grid key={leader.name} item xs={12} md={4} xl={3}>
              <TeamMemberCard
                avatar={leader.avatar}
                title={leader.name}
                subtitle={leader.role}
                description={leader.description}
                linkedIn={leader.linkedIn}
              />
            </Grid>
          ))}
        </Grid>
        <Typography
          color="primary"
          className={clsx(classes.subtitle, classes.marginTop)}
          align="center"
        >
          MORE
        </Typography>
      </Container>
    </div>
  );
};

export default React.memo(Leaders);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(8, 0),
      position: "relative",
    },
    gCircle1: { position: "absolute", bottom: theme.spacing(16) },
    gCircle2: {
      position: "absolute",
      width: 51,
      height: 50,
      top: theme.spacing(24),
      right: theme.spacing(8),
    },
    gCircle3: {
      position: "absolute",
      width: 97,
      height: 95,
      top: theme.spacing(30),
      right: 0,
    },
    title: {
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
    },
    subtitleContainer: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    subtitle: {
      margin: theme.spacing(2, 0),
      fontSize: theme.typography.pxToRem(20),
    },
    marginTop: { marginTop: theme.spacing(6) },
  })
);

const leaders = [
  {
    name: "Vaibhav",
    role: "Sr. Frontend Developer",
    description:
      "Hi I’m Vaibhav, Participation in full software development lifecycle including requirements analysis and design, working closely with technical, design, and product teams. Handling end-end web as well as App development too for PlayCTL.",
    avatar: img3,
    linkedIn: "https://www.linkedin.com/in/vaibhavhrt/",
  },
  {
    name: "Abhishek",
    role: "Sr. Backend Developer",
    description:
      "Hello I’m Abhishek Experienced Back End Developer with a demonstrated history of working in the information technology and services industry. Skilled in NoSQL, Node.js, AngularJS, JavaScript, and MongoDB. Handling end-end backend Infrastructure for PlayCTL.",
    avatar: img4,
    linkedIn: "https://www.linkedin.com/in/abhishek-thareja-583090b8/",
  },
  {
    name: "Amit Singh",
    role: "Cloud & DevOps",
    description:
      "Hi, I’m Amit, A dynamic Senior IT professional with years of experience in the analysis, design, development, migration, and implementation. Currently working as AWS Cloud architect as well DevOps expert with PlayCTL team.",
    // avatar: img2,
    linkedIn: "https://www.linkedin.com/",
  },
];
