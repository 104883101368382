import jwtDecode from "jwt-decode";

interface IDecodedToken {
  _id: string;
  email: string;
  role: "user" | "admin";
  iat: number;
}

export const getAuthTokenFromCookie = () => {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith("token="))
    ?.split("=")[1];
  return cookieValue;
};

export const getLocalJwtToken = () => {
  const token = getAuthTokenFromCookie();
  if (!token) return null;
  const decoded = jwtDecode<IDecodedToken>(token);
  // console.log(decoded);
  // const expired = decoded.exp < Date.now() / 1000;
  // if (expired) return null;
  return { token, ...decoded };
};
