import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { createMuiTheme, ThemeProvider, CssBaseline } from "@material-ui/core";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import configureStore from "./_redux/store/configureStore";
// @ts-ignore
const store = configureStore();

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Rubik", "Helvetica", "Arial", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#4043FF",
      light: "#8570ff",
      dark: "#0018ca",
      contrastText: "#fff",
    },
    secondary: {
      main: "#3be4ce",
      light: "#7cffff",
      dark: "#00b19d",
      contrastText: "#fff",
    },
    error: {
      main: "#fc6e4f",
      light: "#ffa07c",
      dark: "#c33d25",
      contrastText: "#fff",
    },
    background: {
      paper: "#130D4A",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffffb3",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
