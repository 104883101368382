import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, createStyles, Theme, Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";

import { HeroBase } from "../../components";
import { Button } from "../../_lib";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnRoot: { width: 250, backgroundColor: "#03004D" },
    goBackLink: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: theme.spacing(4),
      color: theme.palette.text.primary,
      textDecoration: "none",
    },
    backIcon: { marginRight: theme.spacing(1) },
  })
);

const Hero: React.FC = () => {
  const classes = useStyles();

  const renderButtons = (
    <Button
      variant="contained"
      color="inherit"
      size="large"
      classes={{ root: classes.btnRoot }}
    >
      Get your referral code
    </Button>
  );

  return (
    <HeroBase
      title="Our Referral Program"
      subtitle="Our three tier referral program gives you an opportunity to earn
    upto 3 levels of referrals."
      buttons={renderButtons}
    >
      <Link to="/" className={classes.goBackLink}>
        <ChevronLeft className={classes.backIcon} />
        <Typography variant="h5" align="center">
          Go back to home
        </Typography>
      </Link>
    </HeroBase>
  );
};

export default React.memo(Hero);
