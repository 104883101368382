import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
const GooglePlay: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 32.446 35.993">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32.446"
        height="35.993"
        viewBox="0 0 32.446 35.993"
      >
        <path
          fill="currentColor"
          d="M22.873,16.474,7.355.914,27.1,12.248l-4.226,4.226ZM3.3,0A2.733,2.733,0,0,0,1.779,2.482V33.511A2.733,2.733,0,0,0,3.3,35.993l18.042-18ZM33.2,15.863l-4.141-2.4L24.441,18l4.62,4.535,4.226-2.4a2.821,2.821,0,0,0-.084-4.275ZM7.355,35.086,27.1,23.752l-4.226-4.226Z"
          transform="translate(-1.779)"
        />
      </svg>
    </SvgIcon>
  );
};

export default React.memo(GooglePlay);
