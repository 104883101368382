import React from "react";
import clsx from "clsx";

import { makeStyles, createStyles, Theme } from "@material-ui/core";

interface OvalGradientProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  gradientStart?: string;
  gradientEnd?: string;
}

const defaultProps: OvalGradientProps = {
  gradientStart: "#3139EC",
  gradientEnd: "#FF0061",
};

const OvalGradient: React.FC<OvalGradientProps> = (props) => {
  const finalProps = { ...defaultProps, ...props };
  const classes = useStyles(finalProps);
  const { gradientStart, gradientEnd, ...propsToForward } = props;

  return (
    <div {...propsToForward} className={clsx(classes.root, props.className)} />
  );
};

export default React.memo(OvalGradient);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: OvalGradientProps) => ({
      width: 132,
      height: 129,
      borderRadius: 999,
      opacity: 0.2,
      background: `transparent linear-gradient(218deg, ${props.gradientStart} 0%, ${props.gradientEnd} 100%) 0% 0% no-repeat padding-box`,
    }),
  })
);
