export const RECEIVE_PROFILE_DATA = "RECEIVE_PROFILE_DATA";

// Actions
interface ReceiveProfileDataAction {
  type: typeof RECEIVE_PROFILE_DATA;
  data: {
    name: string;
    email: string;
    refcode: string;
    wallet: number;
    profileImg: string;
    joineDate: string;
  };
}

export type AuthActionTypes = ReceiveProfileDataAction;
