import React, { useCallback, useRef } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Container,
  IconButton,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import Header from "./Header";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: `transparent linear-gradient(61deg, #060220 0%, #4043FF 100%) 0% 0% no-repeat padding-box`,
      borderBottomLeftRadius: 200,
      paddingBottom: theme.spacing(2.5),
      [theme.breakpoints.only("xs")]: { borderBottomLeftRadius: 100 },
    },
    container: { color: "#FFFFFF" },
    title: {
      fontSize: theme.typography.pxToRem(42),
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up("md")]: { fontSize: theme.typography.pxToRem(72) },
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(18),
      [theme.breakpoints.up("md")]: { fontSize: theme.typography.pxToRem(24) },
    },
    btnContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      margin: theme.spacing(3, 0),
    },
    expandIconContainer: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

interface HeroBaseProps {
  title: string;
  subtitle: string;
  buttons?: React.ReactNode;
  showScrollDown?: boolean;
  children?: React.ReactNode;
}
const HeroBase = React.forwardRef<HTMLDivElement, HeroBaseProps>(
  (props, forwardedRef) => {
    const { children, title, subtitle, buttons, showScrollDown = true } = props;
    const classes = useStyles();

    const innerRef = useRef<HTMLDivElement | null>(null);

    function setRef(ref: HTMLDivElement) {
      assignForwardedRefs(forwardedRef, ref);
      innerRef.current = ref;
    }

    const scrollDown = useCallback(() => {
      window.scrollTo({
        behavior: "smooth",
        top: innerRef.current?.clientHeight,
      });
    }, [innerRef]);

    return (
      <div className={classes.root} ref={setRef}>
        <Header />
        <Container className={classes.container} maxWidth="md">
          <Typography variant="h1" align="center" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="h5" align="center" className={classes.subtitle}>
            {subtitle}
          </Typography>
          <div className={classes.btnContainer}>{buttons}</div>
          {children}
          {showScrollDown && (
            <div className={classes.expandIconContainer}>
              <IconButton onClick={scrollDown} color="inherit">
                <ExpandMore />
              </IconButton>
            </div>
          )}
        </Container>
      </div>
    );
  }
);

export default React.memo(HeroBase);

function assignForwardedRefs(
  forwardedRef:
    | ((instance: HTMLDivElement | null) => void)
    | React.MutableRefObject<HTMLDivElement | null>
    | null,
  refToAssign: HTMLDivElement
) {
  if (forwardedRef) {
    if (typeof forwardedRef === "function") {
      forwardedRef(refToAssign);
    } else {
      forwardedRef.current = refToAssign;
    }
  }
}
