import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Container,
  Grid,
  Typography,
  Hidden,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";

import { SectionTitle } from "../../components";

import imgSvg from "./_assets/GameOfSkill.svg";
import imgPng from "./_assets/GameOfSkillPlayer.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      paddingTop: theme.spacing(32),
      paddingBottom: theme.spacing(10),
      backgroundImage: `url(${imgSvg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top right",
    },
    subtitle: {
      margin: theme.spacing(5, 0),
    },
    bulletPointRoot: { display: "flex", margin: theme.spacing(1, 0) },
    bulletPointText: {
      fontSize: theme.typography.pxToRem(18),
      marginLeft: theme.spacing(1),
    },
  })
);

const GameOfSkill: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={4}>
          <Hidden smDown>
            <Grid item xs={12} md={6}>
              <img src={imgPng} alt="CTL Player" />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={6}>
            <div>
              <SectionTitle>
                It is a <br /> Game of Skill
              </SectionTitle>
              <Typography variant="h5" className={classes.subtitle}>
                Playing against other players, with your Crypto Trading Skills
                and shine in the leaderboard.
              </Typography>
              {bulletPoints.map((point) => (
                <div className={classes.bulletPointRoot} key={point}>
                  <Check color="secondary" fontSize="small" />
                  <Typography className={classes.bulletPointText}>
                    {point}
                  </Typography>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default React.memo(GameOfSkill);

const bulletPoints = [
  "Uses trading strategies",
  "Mathematics and probability techniques",
  "Compete others with buying and selling cryptos",
  "Doesn’t match trading on the basis of volume, liquidity, and other factors",
];
