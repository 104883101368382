import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";

import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { Paper, Typography, TextField } from "@material-ui/core";

import { submitEnquiryForm } from "../../_redux/auth";

import { Button } from "../../_lib";

const RequestDemoForm: React.FC = () => {
  const classes = useStyles();

  const dispatch: AppDispatch = useDispatch();

  const form = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      contact: "",
      emailId: "",
      companyName: "",
      message: "",
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      contact: yup.string().required(),
      emailId: yup.string().email().required(),
      companyName: yup.string().required(),
      message: yup.string().required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      const error = await dispatch(submitEnquiryForm(values));
      if (!error) resetForm();
    },
  });

  const {
    errors: formErrors,
    touched,
    getFieldProps,
    handleSubmit,
    isSubmitting,
  } = form;
  const getError = (field: keyof typeof form.initialValues) =>
    !!(formErrors[field] && touched[field]);
  const getHelperText = (field: keyof typeof form.initialValues) =>
    touched[field] && formErrors[field];

  return (
    <Paper className={classes.root}>
      <Typography className={classes.titleText}>Request a demo</Typography>
      <form onSubmit={handleSubmit}>
        {/* First Name */}
        <TextField
          placeholder="First Name"
          fullWidth
          variant="outlined"
          className={classes.textField}
          error={getError("firstName")}
          helperText={getHelperText("firstName")}
          {...getFieldProps("firstName")}
        />
        {/* Last Name */}
        <TextField
          placeholder="Last Name"
          fullWidth
          variant="outlined"
          className={classes.textField}
          error={getError("lastName")}
          helperText={getHelperText("lastName")}
          {...getFieldProps("lastName")}
        />
        {/* Contact Number */}
        <TextField
          placeholder="Contact Number"
          fullWidth
          variant="outlined"
          className={classes.textField}
          error={getError("contact")}
          helperText={getHelperText("contact")}
          {...getFieldProps("contact")}
        />
        {/* Email ID */}
        <TextField
          placeholder="Email ID"
          fullWidth
          variant="outlined"
          className={classes.textField}
          error={getError("emailId")}
          helperText={getHelperText("emailId")}
          {...getFieldProps("emailId")}
        />
        {/* Company Name */}
        <TextField
          placeholder="Company Name"
          fullWidth
          variant="outlined"
          className={classes.textField}
          error={getError("companyName")}
          helperText={getHelperText("companyName")}
          {...getFieldProps("companyName")}
        />
        {/* Message */}
        <TextField
          placeholder="Message"
          fullWidth
          variant="outlined"
          className={classes.textField}
          multiline
          rowsMax={4}
          rows={4}
          error={getError("message")}
          helperText={getHelperText("message")}
          {...getFieldProps("message")}
        />
        {/* Submit */}
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          className={classes.submitBtn}
          disabled={isSubmitting}
        >
          Submit
        </Button>
      </form>
    </Paper>
  );
};

export default React.memo(RequestDemoForm);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#03004D",
      padding: theme.spacing(5, 2),
      [theme.breakpoints.up("sm")]: { padding: theme.spacing(5, 7) },
    },
    titleText: {
      fontSize: theme.typography.pxToRem(24),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.only("xs")]: { textAlign: "center" },
    },
    textField: {
      margin: theme.spacing(1, 0),
      // backgroundColor: "#F8FAFD",
      borderRadius: 32,
      "& .MuiInputBase-root": {
        borderRadius: 32,
        color: theme.palette.primary.main,
        background: "#F8FAFD",
      },
      "& label, & label.Mui-focused": {
        color: theme.palette.primary.main,
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#F8FAFD",
        },
      },
    },
    submitBtn: {
      width: "100%",
      marginTop: theme.spacing(1),
      borderRadius: 999,
    },
  })
);
