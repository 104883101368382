import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const Telegram: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 34.875 34.875">
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.875"
        height="34.875"
        viewBox="0 0 34.875 34.875"
      > */}
        <path
          d="M17.438.563A17.438,17.438,0,1,0,34.875,18,17.434,17.434,0,0,0,17.438.563ZM26,12.509,23.14,25.995c-.211.956-.78,1.188-1.575.738L17.205,23.52l-2.1,2.025a1.1,1.1,0,0,1-.879.429l.309-4.437,8.079-7.3c.352-.309-.077-.485-.541-.176l-9.984,6.286-4.3-1.343c-.935-.3-.956-.935.2-1.385l16.812-6.483c.78-.281,1.462.19,1.209,1.371Z"
          transform="translate(0 -0.563)"
          fill="currentColor"
        />
      {/* </svg> */}
    </SvgIcon>
  );
};

export default React.memo(Telegram);
