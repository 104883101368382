import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Container,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";

import { Button } from "../../_lib";
import { SectionTitle } from "../../components";

import BlogImg0 from "./_assets/BlogCard-0.png";
import BlogImg1 from "./_assets/BlogCard-1.png";
import BlogImg2 from "./_assets/BlogCard-2.png";
import BlogBg1 from "./_assets/BlogBg1.svg";
import BlogBg2 from "./_assets/BlogBg2.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(12, 0),
    },
    sectionTitle: {
      "&:before": {
        content: '" "',
        right: "-20%",
        bottom: 5,
        width: "70%",
        [theme.breakpoints.only("xs")]: {
          right: "-10%",
        },
      },
    },
    subtitle: {
      margin: theme.spacing(2, 0),
    },
    btnRoot: { width: 200 },
    blogCardContainer: {
      position: "relative",
      padding: theme.spacing(8, 0),
    },
    blogCardContainerOverlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: `url(${BlogBg1})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "70%",
      backgroundPositionY: "5%",
      "&:before": {
        content: '" "',
        position: "absolute",
        height: "100%",
        width: "100%",
        backgroundImage: `url(${BlogBg2})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "20%",
        backgroundPositionY: "bottom",
      },
    },
    blogCardGrid: {
      zIndex: 1,
    },
  })
);

const CheckOurBlogs: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <SectionTitle color="error" className={classes.sectionTitle}>
          Check our blogs
        </SectionTitle>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h5"
              className={classes.subtitle}
              color="textSecondary"
            >
              We keep eyes on the most recent activities of crypto world, Read
              our latest blogs…
            </Typography>
          </Grid>
          <Box
            clone
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                classes={{ root: classes.btnRoot }}
              >
                Go to blogs
              </Button>
            </Grid>
          </Box>
        </Grid>
        <Grid container spacing={2} className={classes.blogCardContainer}>
          <div className={classes.blogCardContainerOverlay} />
          {blogs.map((blog) => (
            <Grid
              item
              xs={12}
              md={4}
              key={blog.title}
              className={classes.blogCardGrid}
            >
              <BlogCard blog={blog} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default React.memo(CheckOurBlogs);

interface IBlog {
  title: string;
  subtitle: string;
  img: string;
}
const blogs: IBlog[] = [
  { title: "App for iWatch", subtitle: "Development", img: BlogImg0 },
  { title: "Funny App", subtitle: "Design", img: BlogImg1 },
  { title: "Business App", subtitle: "Marketing", img: BlogImg2 },
];

const useBlogCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 380,
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      position: "relative",
    },
    absContent: {
      position: "absolute",
      padding: theme.spacing(6, 4),
    },
    title: { fontSize: theme.typography.pxToRem(30) },
    subtitle: { fontSize: theme.typography.pxToRem(18) },
  })
);

interface BlogCardProps {
  blog: IBlog;
}
const BlogCard: React.FC<BlogCardProps> = (props) => {
  const { blog } = props;
  const classes = useBlogCardStyles(props);

  return (
    <div className={classes.root}>
      <img src={props.blog.img} alt="" />
      <div className={classes.absContent}>
        <Typography variant="h5" className={classes.title}>
          {blog.title}
        </Typography>
        <Typography className={classes.subtitle} color="textSecondary">
          {blog.subtitle}
        </Typography>
      </div>
    </div>
  );
};
