import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { animated, useSpring } from "react-spring";

import {
  makeStyles,
  createStyles,
  Theme,
  AppBar,
  Toolbar,
  Container,
  Box,
  IconButton,
  Drawer,
  Divider,
  Typography,
  Avatar,
} from "@material-ui/core";
import { Menu as MenuIcon, Close as CloseIcon } from "@material-ui/icons";

import CTLLogo from "../assets/Images/CTLLogo.svg";
import { Button } from "../_lib";

import { useTypedSelector as useSelector } from "../_redux/rootReducer";
import { getProfile } from "../_redux/auth";
import {
  getIsLoggedIn,
  selectUserName,
  selectWalletBalance,
  selectUserImg,
} from "../_redux/auth/selectors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      padding: theme.spacing(3, 0),
      color: "#fff",
    },
    grow: { flexGrow: 1 },
    btn: { margin: theme.spacing(0, 1) },
    btnActive: { textDecoration: "underline" },
    signInBtn: { backgroundColor: "#03004D", width: 130 },
    rightContainer: {
      display: "flex",
      alignItems: "center",
    },
    drawerPaper: {
      width: 400,
      maxWidth: "100vw",
      backgroundColor: "#130D4A",
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
    },
    navLink: {
      margin: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      fontSize: "0.875rem",
      letterSpacing: -0.54,
      color: "#fff",
      opacity: 0.3,
      textDecoration: "none",
      display: "inline-flex",
      alignItems: "center",
    },
    navLinkActive: {
      opacity: 1,
      borderBottom: "2px solid #4043FF",
    },
    userContainer: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    userAvatar: {
      height: 52,
      width: 52,
      margin: theme.spacing(0, 2),
    },
    userBalanceText: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 14,
      letterSpacing: -0.25,
      color: "#3BE4CE",
    },
    userNameText: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 14,
      color: "#FFFFFF",
    },
    userNameMobileText: {
      wordBreak: "break-word",
    },
    undecoratedLink: {
      textDecoration: "none",
    },
  })
);

const Header: React.FC = () => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();

  const isLoggedIn = useSelector(getIsLoggedIn);
  const userImg = useSelector(selectUserImg);
  const userName = useSelector(selectUserName);
  const walletBalance = useSelector(selectWalletBalance);
  const animatedWalletBalance = useSpring({
    bal: walletBalance,
    config: { duration: 800 },
  });

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = useCallback(() => setDrawerOpen(true), []);
  const handleDrawerClose = useCallback(() => setDrawerOpen(false), []);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const buttons = useMemo(() => {
    const btns = [
      { label: "Home", to: "/" },
      { label: "How to Play", to: "/how-it-works" },
      { label: "Buy Tickets", href: "https://app.playctl.net/leagues" },
      { label: "Team", to: "/team" },
    ];
    if (!isLoggedIn) btns.push({ label: "Sign Up", href: "https://app.playctl.net/auth/signup" });
    return btns;
  }, [isLoggedIn]);

  return (
    <Container>
      <AppBar
        position="static"
        color="transparent"
        className={classes.appbar}
        elevation={0}
      >
        <Toolbar>
          <Link to="/">
            <img src={CTLLogo} alt="CTL Logo" />
          </Link>
          <div className={classes.grow} />
          {/* hide on screens smaller than md */}
          <Box
            display={{ xs: "none", md: "flex" }}
            marginX={2}
            alignItems="center"
          >
            {buttons.map((btn) =>
              btn.to ? (
                <Button
                  key={btn.label}
                  className={classes.btn}
                  // @ts-ignore
                  component={NavLink}
                  to={btn.to}
                  activeClassName={classes.btnActive}
                  exact
                >
                  {btn.label}
                </Button>
              ) : (
                <Button
                  key={btn.label}
                  className={classes.btn}
                  // @ts-ignore
                  component={"a"}
                  href={btn.href}
                >
                  {btn.label}
                </Button>
              )
            )}
            {!!isLoggedIn ? (
              <a
                href="https://app.playctl.net/"
                className={classes.undecoratedLink}
              >
                <div className={classes.userContainer}>
                  <Avatar
                    src={userImg}
                    alt="user avatar"
                    className={classes.userAvatar}
                  >
                    {userName[0]}
                  </Avatar>
                  <div>
                    <Typography className={classes.userBalanceText}>
                      <animated.span>
                        {animatedWalletBalance.bal.to((x) => x.toFixed(8))}
                      </animated.span>
                      BTC
                    </Typography>
                    <Typography className={classes.userNameText}>
                      {userName}
                    </Typography>
                  </div>
                </div>
              </a>
            ) : (
              <Button
                variant="contained"
                color="inherit"
                className={clsx(classes.btn, classes.signInBtn)}
                // @ts-ignore
                component="a"
                href="https://app.playctl.net/auth/signin"
              >
                Sign In
              </Button>
            )}
          </Box>
          {/* Right */}
          <div className={classes.rightContainer}>
            <Box ml={2} display={{ md: "none" }}>
              <IconButton color="secondary" onClick={handleDrawerOpen}>
                <MenuIcon />
              </IconButton>
            </Box>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        classes={{ paper: classes.drawerPaper }}
        keepMounted
      >
        <Box display="flex" alignItems="center" padding={2}>
          {!!isLoggedIn && (
            <a
              href="https://app.playctl.net/"
              className={classes.undecoratedLink}
            >
              <Box color="#FFFFFF">
                <Typography
                  variant="h4"
                  color="inherit"
                  className={classes.userNameMobileText}
                >
                  {userName}
                </Typography>
                <Box color="#748AA1">
                  <Typography color="inherit">
                    {walletBalance?.toFixed(8)} BTC
                  </Typography>
                </Box>
              </Box>
            </a>
          )}
          <Box display="flex" flexGrow={1} />
          <IconButton color="secondary" onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider className={classes.divider} />
        {buttons.map((btn) =>
          btn.to ? (
            <NavLink
              key={btn.label}
              to={btn.to}
              onClick={handleDrawerClose}
              className={classes.navLink}
              activeClassName={classes.navLinkActive}
              exact
            >
              {btn.label}
            </NavLink>
          ) : (
            <a key={btn.label} href={btn.href} className={classes.navLink}>
              {btn.label}
            </a>
          )
        )}
        {!isLoggedIn && (
          <a href="https://app.playctl.net/auth/signin" className={classes.navLink}>
            Sign In
          </a>
        )}
      </Drawer>
    </Container>
  );
};

export default React.memo(Header);
