import React from "react";
import { Link } from "react-router-dom";
import {
  makeStyles,
  createStyles,
  Theme,
  Container,
  Grid,
  Typography,
  Divider,
  Box,
} from "@material-ui/core";
import Color from "color";

import CTLLogo from "../assets/Images/CTLLogo.svg";
import { Facebook, Twitter, LinkedIn, Telegram } from "../assets/Icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#03004D",
      padding: theme.spacing(5, 0, 2),
    },
    titleText: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightMedium,
      margin: theme.spacing(4, 0),
    },
    subtitleText: {
      margin: theme.spacing(1, 0),
      "&>a": {
        textDecoration: "none",
        color: "inherit",
      },
    },
    divider: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      backgroundColor: Color("#fff").alpha(0.12).toString(),
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // color: "#fff",
      borderRadius: "50%",
      border: `2px solid #fff`,
      height: 48,
      width: 48,
      margin: theme.spacing(0, 0.5),
    },
    link: {
      textDecoration: "none",
      color: "inherit",
    },
  })
);

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <img src={CTLLogo} alt="CTL Logo" />
            <Typography className={classes.titleText}>
              <a href="tel:+918588040488" className={classes.link}>
                +91 8588040488
              </a>
            </Typography>
            <Typography color="textSecondary">
              <a
                href="mailto:support@playctl.net"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                support@playctl.net
              </a>
            </Typography>
          </Grid>
          {/* Menu */}
          <Grid item xs={12} sm={4}>
            <Typography className={classes.titleText}>MENU</Typography>
            <Grid container>
              {menu.map((x) => (
                <Grid key={x.label} item xs={6}>
                  <Typography
                    color="textSecondary"
                    className={classes.subtitleText}
                  >
                    {x.to ? (
                      <Link to={x.to}>{x.label}</Link>
                    ) : (
                      <a href={x.href} target="_blank" rel="noreferrer">
                        {x.label}
                      </a>
                    )}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {/* SOCIALS */}
          <Grid item xs={12} sm={4}>
            <Typography className={classes.titleText}>SOCIALS</Typography>
            <Grid container>
              {/* Facebook */}
              <Grid item xs={6}>
                <Typography
                  color="textSecondary"
                  className={classes.subtitleText}
                >
                  <a
                    href="https://www.facebook.com/PlayCTLOfficial"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    Facebook
                  </a>
                </Typography>
              </Grid>
              {/* Instagram */}
              <Grid item xs={6}>
                <Typography
                  color="textSecondary"
                  className={classes.subtitleText}
                >
                  Instagram
                </Typography>
              </Grid>
              {/* Twitter */}
              <Grid item xs={6}>
                <Typography
                  color="textSecondary"
                  className={classes.subtitleText}
                >
                  <a
                    href="https://twitter.com/playctlofficial"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    Twitter
                  </a>
                </Typography>
              </Grid>
              {/* Linkedin */}
              <Grid item xs={6}>
                <Typography
                  color="textSecondary"
                  className={classes.subtitleText}
                >
                  <a
                    href="https://www.linkedin.com/company/playctl/"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    Linkedin
                  </a>
                </Typography>
              </Grid>
              {/* Telegram */}
              <Grid item xs={6}>
                <Typography
                  color="textSecondary"
                  className={classes.subtitleText}
                >
                  <a
                    href="https://t.me/PlayCTL"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    Telegram
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Box
          display="flex"
          justifyContent={{ xs: "center", sm: "space-between" }}
          flexWrap="wrap"
          alignItems="center"
        >
          <Box display="flex" justifyContent="center" mb={1}>
            {/* FC ICON */}
            <a
              href="https://www.facebook.com/PlayCTLOfficial"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              <div className={classes.iconContainer}>
                <Facebook />
              </div>
            </a>
            {/* Twitter ICON */}
            <a
              href="https://twitter.com/playctlofficial"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              <div className={classes.iconContainer}>
                <Twitter />
              </div>
            </a>
            {/* LinkedIn ICON */}
            <a
              href="https://www.linkedin.com/company/playctl/"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              <div className={classes.iconContainer}>
                <LinkedIn />
              </div>
            </a>
            {/* Telegram ICON */}
            <a
              href="https://t.me/PlayCTL"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              <div className={classes.iconContainer}>
                <Telegram />
              </div>
            </a>
          </Box>
          <Typography align="center">
            © 2020. All right reserved. World Trading League PVT. LTD.
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default React.memo(Footer);

const menu = [
  { label: "Privacy Policy", to: "/privacy" },
  { label: "Blog", href: "https://lib.playctl.net/blog" },
  { label: "Terms of Services", to: "/terms" },
  { label: "Contact us", to: "/contact" },
  { label: "Terms of Use", to: "/terms-of-use" },
  { label: "FAQs", to: "/faq" },
];
