import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { Container, Grid } from "@material-ui/core";

import { Footer } from "../../components";
import Hero from "./Hero";
import SendRequestInfo from "./SendRequestInfo";
import RequestDemoForm from "./RequestDemoForm";
import GetInTouch from "./GetInTouch";

const Contact: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hero />
      <div className={classes.content}>
        <Container maxWidth="md">
          <SendRequestInfo />
          <div className={classes.innerContent}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <RequestDemoForm />
              </Grid>
              <Grid item xs={12} md={6}>
                <GetInTouch />
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default React.memo(Contact);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#060220",
      minHeight: "100vh",
    },
    content: {
      padding: theme.spacing(8, 0),
    },
    innerContent: {
      marginTop: theme.spacing(6),
    },
  })
);
