import React from "react";
import { Link } from "react-router-dom";
import {
  makeStyles,
  createStyles,
  Theme,
  Container,
  Typography,
  Grid,
} from "@material-ui/core";

import { Footer } from "../../components";
import Hero from "./Hero";
import Item from "./Item";

import faq from "./faq.json";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#060220",
      minHeight: "100vh",
    },
    container: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    content: {
      padding: theme.spacing(5, 0),
    },
    link: {
      color: "inherit",
      textDecoration: "none",
    },
  })
);

const FAQs: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hero />
      <Container className={classes.container}>
        <Typography color="primary" variant="h4">
          F.A.Q
        </Typography>
        <Typography color="secondary">
          Questions, Concerns or something else?
        </Typography>
        <Grid container className={classes.content} spacing={4}>
          {faq.map((x) => (
            <Grid key={x.question} item xs={12} sm={4}>
              <Item question={x.question} answer={x.answer} />
            </Grid>
          ))}
        </Grid>
        <Typography color="secondary">
          <Link to="/contact" className={classes.link}>
            Still have a Question? Contact Us
          </Link>
        </Typography>
      </Container>
      <Footer />
    </div>
  );
};

export default FAQs;
