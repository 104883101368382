import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";

import { makeStyles, createStyles, Theme, Typography } from "@material-ui/core";

import { HeroBase, YoutubeVideoEmbedDialog } from "../../components";
import { Button } from "../../_lib";
import { Video } from "../../assets/Icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnRoot: { width: 200 },
    leftBtn: {
      backgroundColor: "#03004D",
      [theme.breakpoints.only("xs")]: {
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.up("sm")]: {
        marginRight: theme.spacing(1),
      },
    },
    rightBtn: {
      backgroundColor: "#fff",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
      },
    },
    playVideoContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: theme.spacing(10),
    },
    playVideoIcon: { marginRight: 16 },
  })
);

const Hero: React.FC = () => {
  const classes = useStyles();
  const [ytOpen, setYtOpen] = useState(false);
  const openYtDialog = useCallback(() => setYtOpen(true), []);
  const closeYtDialog = useCallback(() => setYtOpen(false), []);

  const renderButtons = (
    <>
      <Button
        variant="contained"
        color="inherit"
        size="large"
        classes={{ root: classes.btnRoot }}
        className={classes.leftBtn}
        // @ts-ignore
        component={"a"}
        href="https://app.playctl.net/leagues"
      >
        Buy Tickets
      </Button>
      <Button
        variant="contained"
        size="large"
        classes={{ root: classes.btnRoot }}
        className={classes.rightBtn}
        // @ts-ignore
        component={Link}
        to="/how-it-works"
      >
        How it works
      </Button>
    </>
  );

  return (
    <HeroBase
      title="Gamified mock trading platform for crypto"
      subtitle="We are training the new breed of investors by preparing them through
    an incentivised trading engine."
      buttons={renderButtons}
    >
      <div className={classes.playVideoContainer}>
        <Video color="error" className={classes.playVideoIcon} />
        <Typography
          variant="h5"
          align="center"
          color="error"
          onClick={openYtDialog}
        >
          Play video
        </Typography>
        <YoutubeVideoEmbedDialog
          src="https://www.youtube.com/embed/OF65uIQ_vqg"
          open={ytOpen}
          handleClose={closeYtDialog}
        />
      </div>
    </HeroBase>
  );
};

export default React.memo(Hero);
