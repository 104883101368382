import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Container,
  Typography,
} from "@material-ui/core";

import { Button } from "../../_lib";
import { SectionTitle, YoutubeVideoEmbedDialog } from "../../components";
import img from "./_assets/ReferralProgram.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(5),
    },
    content: {
      padding: theme.spacing(0, 5),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 2),
      },
    },
    title: {
      marginBottom: theme.spacing(4),
    },
    subtitle: {
      margin: theme.spacing(2, 0),
      fontSize: theme.typography.pxToRem(20),
    },
    btnContainer: {
      display: "flex",
      flexWrap: "wrap",
      [theme.breakpoints.only("xs")]: { justifyContent: "center" },
    },
    btnRoot: {
      width: 200,
      margin: theme.spacing(0, 1),
      [theme.breakpoints.only("xs")]: { margin: theme.spacing(1, 0) },
    },
  })
);

const ReferralProgram: React.FC = () => {
  const classes = useStyles();

  const [ytOpen, setYtOpen] = useState(false);
  const openYtDialog = useCallback(() => setYtOpen(true), []);
  const closeYtDialog = useCallback(() => setYtOpen(false), []);

  return (
    <Container className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className={classes.content}>
            <SectionTitle
              color="error"
              after={titleAfterStyle}
              rootClass={classes.title}
            >
              Three Tier <br /> Referral Program
            </SectionTitle>
            <Typography variant="h5">
              Multi-Level Referral to invite more users to the ecosystem.
            </Typography>
            <Typography className={classes.subtitle}>
              Upon spending any amount of money on the platform, the invitee
              will get a referral bonus depending on the referral level.
            </Typography>
            <div className={classes.btnContainer}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                classes={{ root: classes.btnRoot }}
              >
                Get referral code
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                classes={{ root: classes.btnRoot }}
                // @ts-ignore
                component={Link}
                to="/referral-program"
              >
                How it works
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.content}>
            <img
              src={img}
              alt="img"
              height="100%"
              width="100%"
              onClick={openYtDialog}
            />
            <YoutubeVideoEmbedDialog
              src="https://www.youtube.com/embed/OF65uIQ_vqg"
              open={ytOpen}
              handleClose={closeYtDialog}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default React.memo(ReferralProgram);

const titleAfterStyle = {
  width: "100%",
  left: 0,
  bottom: 5,
};
