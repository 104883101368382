import React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { Grid, Typography } from "@material-ui/core";

const SendRequestInfo: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} sm={8} xl={6}>
        <div className={classes.headingContainer}>
          <div className={classes.headingLine} />
          <Typography className={classes.heading}>Contacts</Typography>
        </div>
        <Typography className={classes.title}>Send Your Request</Typography>
        <Typography className={classes.subtitle} color="textSecondary">
          Write us with your queries or your interest in our product, one of our
          senior consultant will get in touch with your shortly !
        </Typography>
      </Grid>
    </Grid>
  );
};

export default React.memo(SendRequestInfo);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headingContainer: {
      display: "flex",
      alignItems: "center",
    },
    headingLine: {
      height: 2,
      width: 28,
      backgroundColor: "#878F9D",
      marginRight: theme.spacing(2),
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
    },
    title: {
      fontSize: theme.typography.pxToRem(36),
      fontWeight: theme.typography.fontWeightBold,
      margin: theme.spacing(2, 0),
      [theme.breakpoints.up("sm")]: { fontSize: theme.typography.pxToRem(40) },
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(18),
    },
  })
);
