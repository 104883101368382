import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
const Video: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 18">
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="18"
        viewBox="0 0 24 18"
      > */}
        <path
          fill="currentColor"
          d="M878,2192.74a1,1,0,0,1-.5-.13A3,3,0,0,1,876,2190v-12a3,3,0,0,1,1.5-2.6,1,1,0,1,1,1,1.73,1,1,0,0,0-.5.87v12a1,1,0,0,0,.5.87,1,1,0,0,1-.5,1.87Zm20.5-.14a3,3,0,0,0,1.5-2.6v-12a3,3,0,0,0-1.49-2.59,1,1,0,1,0-1,1.729,1,1,0,0,1,.5.86v12a1,1,0,0,1-.5.87,1,1,0,1,0,1,1.73ZM882,2191a1,1,0,1,0,1,1A1,1,0,0,0,882,2191Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,886,2191Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,890,2191Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,894,2191Zm-12-16a1,1,0,1,0,1,1A1,1,0,0,0,882,2175Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,886,2175Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,890,2175Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,894,2175Zm-7,13v-6l3.08,2.4-.58.34a1,1,0,1,0,1,1.73l1.86-1.09a1,1,0,0,0,.11-1.65l-5.86-4.57a1,1,0,0,0-1.62.79v8a1,1,0,0,0,2,0Z"
          transform="translate(-876 -2175)"
        />
      {/* </svg> */}
    </SvgIcon>
  );
};

export default React.memo(Video);
