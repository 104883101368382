import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Container,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

import OvalGradient from "../../_lib/OvalGradient";
import { SectionTitle } from "../../components";
import TeamMemberCard from "./components/TeamMemberCard";

import img1 from "./_assets/Img-1.png";
import img2 from "./_assets/Img-2.png";

const Founders: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <OvalGradient className={classes.gCircle1} />
        <OvalGradient className={classes.gCircle2} />
        <OvalGradient
          className={classes.gCircle3}
          gradientStart="#3BE4CEB4"
          gradientEnd="#130D4AAF"
        />
        {/* Title and Subtitle */}
        <Grid container spacing={4} justify="center">
          <Grid item xs={12} md={8} lg={7} xl={6}>
            <SectionTitle
              color="error"
              rootClass={classes.title}
              align="center"
            >
              Founders
            </SectionTitle>
            <Typography className={classes.subtitle} align="center">
              We think big, take smart risks as well decisions, and do things
              that have never been done before,{" "}
              <Typography
                component="span"
                color="secondary"
                className={classes.subtitle}
              >
                Say hello
              </Typography>{" "}
              to our founders.
            </Typography>
          </Grid>
        </Grid>
        {/* Founders Cards */}
        <Grid container spacing={4} justify="center">
          {founders.map((founder) => (
            <Grid key={founder.name} item xs={12} md={4} xl={3}>
              <TeamMemberCard
                avatar={founder.avatar}
                title={founder.name}
                subtitle={founder.role}
                description={founder.description}
                linkedIn={founder.linkedIn}
              />
            </Grid>
          ))}
        </Grid>
        <Typography
          color="primary"
          className={clsx(classes.subtitle, classes.marginTop)}
          align="center"
        >
          READ MORE
        </Typography>
      </Container>
    </div>
  );
};

export default React.memo(Founders);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(8, 0),
      position: "relative",
    },
    gCircle1: { position: "absolute", bottom: theme.spacing(16) },
    gCircle2: {
      position: "absolute",
      width: 51,
      height: 50,
      top: theme.spacing(24),
      right: theme.spacing(8),
    },
    gCircle3: {
      position: "absolute",
      width: 97,
      height: 95,
      top: theme.spacing(30),
      right: 0,
    },
    title: {
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
    },
    subtitleContainer: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    subtitle: {
      margin: theme.spacing(2, 0),
      fontSize: theme.typography.pxToRem(20),
    },
    marginTop: { marginTop: theme.spacing(6) },
  })
);

const founders = [
  {
    name: "Dr. Sindhu Bhaskar",
    role: "Co-Founder & Investor",
    description:
      "Dr. Sindhu Bhaskar is the Chairman and CEO of EST Group. EST Global Inc., is an enterprise based in the MIT ecosystem, incorporated and operating from Cambridge Innovation Center, MIT, USA.",
    avatar: img1,
    linkedIn: "https://www.linkedin.com/in/dr-sindhu-bhaskar-55a84568/",
  },
  {
    name: "Sumit Tomar",
    role: "CEO & Co-Founder",
    description:
      "Engineer by profession, Gamer by heart and designer by passion, with lots of experience with startups and new innovations.",
    avatar: img2,
    linkedIn: "https://www.linkedin.com/in/meemtomar/",
  },
];
