import React from "react";
import clsx from "clsx";
import {
  makeStyles,
  createStyles,
  Theme,
  Box,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0),
    },
    paragraph: {
      marginBottom: theme.spacing(3),
    },
  })
);

interface TncSection {
  title: string;
  content: { text: string; ol?: string[] }[];
}
interface AgreementSectionProps {
  section: TncSection;
}
const AgreementSection: React.FC<AgreementSectionProps> = (props) => {
  const { section } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box my={2}>
        <Typography variant="h5" color="secondary">
          {section.title}
        </Typography>
      </Box>
      {section.content.map((x) => (
        <div key={x.text}>
          <Typography className={clsx({ [classes.paragraph]: !x.ol })}>
            {x.text}
          </Typography>
          {x.ol && (
            <ol>
              {x.ol.map((y, i, arr) => (
                <Typography
                  component="li"
                  key={y}
                  gutterBottom
                  className={clsx({
                    [classes.paragraph]: i === arr.length - 1,
                  })}
                >
                  {y}
                </Typography>
              ))}
            </ol>
          )}
        </div>
      ))}
    </div>
  );
};

export default React.memo(AgreementSection);
