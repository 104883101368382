import React, { useCallback, useRef } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";

import { HeroBase } from "../../components";
import { Button } from "../../_lib";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnRoot: { width: 250, backgroundColor: "#03004D" },
    expandIconContainer: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

const Hero: React.FC = () => {
  const classes = useStyles();

  const ref = useRef<HTMLDivElement | null>(null);

  const scrollDown = useCallback(() => {
    window.scrollTo({
      behavior: "smooth",
      top: ref.current?.clientHeight,
    });
  }, [ref]);

  const renderButtons = (
    <Button
      variant="contained"
      color="inherit"
      size="large"
      classes={{ root: classes.btnRoot }}
      onClick={scrollDown}
    >
      Scroll down
    </Button>
  );

  return (
    <HeroBase
      title="How it works"
      subtitle="Lets quickly understand how CTL works."
      buttons={renderButtons}
      ref={ref}
    />
  );
};

export default React.memo(Hero);
