export const getIsLoggedIn = (state: RootState) => !!state.auth.token;
export const getToken = (state: RootState) => state.auth.token;

export const selectWalletBalance = (state: RootState) =>
  state.auth.walletBTC ?? 0;

export const selectUserEmail = (state: RootState) => state.auth.email ?? "";
export const selectUserName = (state: RootState) => state.auth.name ?? "";
export const selectUserId = (state: RootState) => state.auth._id;
export const selectUserImg = (state: RootState) =>
  state.auth.profileImg ?? undefined;
export const selectUserjoiningDate = ({ auth }: RootState) => auth.joiningDate;
export const selectUserRefCode = ({ auth }: RootState) => auth.refcode;
