import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Container,
  Typography,
} from "@material-ui/core";

import { SectionTitle } from "../../components";
import img from "./_assets/WhatsReferralProgram.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(16, 0),
    },
    imgGrid: { padding: theme.spacing(5) },
    title: {
      marginBottom: theme.spacing(4),
    },
    subtitle: {
      margin: theme.spacing(2, 0),
      fontSize: theme.typography.pxToRem(20),
    },
  })
);

const WhatsReferralProgram: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} className={classes.imgGrid}>
            <img src={img} alt="img" height="100%" width="100%" />
          </Grid>
          <Grid item xs={12} md={6}>
            <SectionTitle color="error" rootClass={classes.title}>
              What is <br /> Referral Program
            </SectionTitle>
            <Typography variant="h5">
              Multi-Level Referral (Three Tier Referral Program ) to invite more
              users to the ecosystem.
            </Typography>
            <Typography className={classes.subtitle}>
              Upon spending any amount of money on the platform, the invitee
              will get a referral bonus depending on the referral level.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default React.memo(WhatsReferralProgram);
