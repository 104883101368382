import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import Color from "color";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: (props: SectionTitleProps) => ({
      position: "relative",
      display: "inline",
      "&:after": {
        content: props.after ? "' '" : undefined,
        position: "absolute",
        backgroundColor: Color("#F0B000").alpha(0.4).toString(),
        height: 14,
        ...props.after,
      },
      "&:before": {
        content: props.before ? "' '" : undefined,
        position: "absolute",
        backgroundColor: Color("#F0B000").alpha(0.4).toString(),
        height: 14,
        ...props.before,
      },
    }),
  })
);

interface SectionTitleProps extends TypographyProps {
  after?: AnyObject;
  before?: AnyObject;
  rootClass?: string;
}
const SectionTitle = React.forwardRef<HTMLSpanElement, SectionTitleProps>(
  (props, ref) => {
    const classes = useStyles(props);
    const { after, before, className, rootClass, ...otherProps } = props;

    return (
      <div className={rootClass}>
        <Typography
          ref={ref}
          variant="h3"
          align="center"
          className={clsx(classes.title, className)}
          {...otherProps}
        />
      </div>
    );
  }
);

export default React.memo(SectionTitle);
