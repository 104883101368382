import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import {
  Landing,
  ReferralProgram,
  TermsAndConditions,
  PrivacyPolicy,
  TermsOfUsage,
  FAQs,
  Team,
  HowItWorks,
  Contact,
} from "./pages";

const App: React.FC = () => {
  const { pathname } = useLocation();

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      <Route path="/" exact>
        <Landing />
      </Route>
      <Route path="/referral-program" exact>
        <ReferralProgram />
      </Route>
      <Route path="/terms" exact>
        <TermsAndConditions />
      </Route>
      <Route path="/privacy" exact>
        <PrivacyPolicy />
      </Route>
      <Route path="/terms-of-use" exact>
        <TermsOfUsage />
      </Route>
      <Route path="/faq" exact>
        <FAQs />
      </Route>
      <Route path="/team" exact>
        <Team />
      </Route>
      <Route path="/how-it-works" exact>
        <HowItWorks />
      </Route>
      <Route path="/contact" exact>
        <Contact />
      </Route>
    </Switch>
  );
};

export default App;
