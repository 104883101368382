import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Container,
  Typography,
} from "@material-ui/core";

import { Button } from "../../_lib";
import { SectionTitle } from "../../components";
import img from "./_assets/WhatIsCrypto.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(5, 0),
    },
    content: {
      padding: theme.spacing(0, 5),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 2),
      },
    },
    title: {
      marginBottom: theme.spacing(4),
    },
    sectionTitle: {
      "&:after": {
        content: '" "',
        width: "40%",
        left: 0,
        bottom: "50%",
        [theme.breakpoints.only("md")]: { bottom: "66%", width: "120%" },
        [theme.breakpoints.only("xs")]: { bottom: "66%", width: "120%" },
      },
    },
    subtitle: {
      margin: theme.spacing(2, 0),
      fontSize: theme.typography.pxToRem(20),
    },
    btnRoot: { width: 200 },
  })
);

const WhatIsCrypto: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className={classes.content}>
            <img src={img} alt="img" height="100%" width="100%" />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.content}>
            <SectionTitle
              color="error"
              className={classes.sectionTitle}
              rootClass={classes.title}
            >
              What is <br /> Crypto Trading League
            </SectionTitle>
            <Typography variant="h5">
              CTL platform is an educational tool for traders to learn the
              basics about how to trade crypto.
            </Typography>
            <Typography className={classes.subtitle}>
              A Gamified crypto mock trading platform to learn trading like a
              real trading simulation software, at the same time users stand a
              chance to win big prizes.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              classes={{ root: classes.btnRoot }}
            >
              Know More
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default React.memo(WhatIsCrypto);
