import React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core";
import {
  Avatar,
  Card,
  CardContent,
  CardActions,
  Typography,
  IconButton,
} from "@material-ui/core";

import { LinkedIn } from "../../../assets/Icons";

interface TeamMemberCardProps {
  avatar?: string;
  title: string;
  subtitle: string;
  description: string;
  linkedIn: string;
}
const TeamMemberCard: React.FC<TeamMemberCardProps> = (props) => {
  const classes = useStyles();
  const { avatar = "", title, subtitle, description, linkedIn } = props;

  return (
    <div className={classes.root}>
      <Card className={classes.cardRoot}>
        <div className={classes.avatarContainer}>
          <Avatar src={avatar} className={classes.avatar} />
        </div>
        <CardContent>
          <Typography align="center" className={classes.title}>
            {title}
          </Typography>
          <Typography align="center" className={classes.subtitle}>
            {subtitle}
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            className={classes.description}
          >
            {description}
          </Typography>
        </CardContent>
        <div className={classes.grow} />
        <CardActions className={classes.cardActions}>
          <IconButton
            color="inherit"
            component="a"
            href={linkedIn}
            target="_blank"
            rel="noreferrer"
          >
            <LinkedIn />
          </IconButton>
        </CardActions>
      </Card>
    </div>
  );
};

export default React.memo(TeamMemberCard);

const AVATAR_SIZE = 140;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { paddingTop: 70, height: "100%" },
    cardRoot: {
      background: "#03004D",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      overflow: "visible",
    },
    avatarContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      height: AVATAR_SIZE / 2,
    },
    avatar: {
      position: "absolute",
      top: -AVATAR_SIZE / 2,
      height: AVATAR_SIZE,
      width: AVATAR_SIZE,
    },
    title: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightBold,
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
    },
    description: {
      fontSize: theme.typography.pxToRem(14),
      marginTop: theme.spacing(3),
    },
    grow: { flexGrow: 1 },
    cardActions: {
      justifyContent: "center",
      padding: theme.spacing(2, 1),
    },
  })
);
