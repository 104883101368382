import React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Backdrop } from "@material-ui/core";

interface YoutubeVideoEmbedDialogProps {
  src: string;
  open: boolean;
  handleClose: () => void;
}
const YoutubeVideoEmbedDialog: React.FC<YoutubeVideoEmbedDialogProps> = (
  props
) => {
  const classes = useStyles();
  const { src, open, handleClose } = props;

  return (
    <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
      <iframe
        width="560"
        height="315"
        src={src}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Backdrop>
  );
};

export default React.memo(YoutubeVideoEmbedDialog);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);
