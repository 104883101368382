import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Container,
  Typography,
  Grid,
} from "@material-ui/core";

import { Button } from "../../_lib";
import { SectionTitle } from "../../components";
import { Apple, GooglePlay } from "../../assets/Icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subtitle: {
      padding: theme.spacing(2, 0),
    },
    secondaryText: {
      fontSize: theme.typography.pxToRem(20),
      [theme.breakpoints.only("xs")]: {
        paddingBottom: theme.spacing(2),
      },
    },
    leftGrid: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    appMarketBtnContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      padding: theme.spacing(8, 0),
      [theme.breakpoints.only("xs")]: { padding: theme.spacing(4, 0) },
    },
    btnRoot: {
      width: 200,
      margin: theme.spacing(0, 2),
      [theme.breakpoints.only("xs")]: { margin: theme.spacing(2, 0) },
    },
  })
);

const BusinessAssociateProgram: React.FC = () => {
  const classes = useStyles();

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} sm={8} md={9}>
          <SectionTitle color="secondary">
            Business Associate Program
          </SectionTitle>
          <Typography variant="h5" className={classes.subtitle}>
            Our Multilevel Business Associate Program gives opportunity to earn
            over and above to our referral program.
          </Typography>
          <Typography className={classes.secondaryText}>
            This is designed to incentivise our influencers as well as marketing
            companies who can get more direct referrals. We categories and rate
            these associates with head counts of direct referrals they achieved
            and basis on that we offer upto additional bonus payout of our
            ticket sale.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3} className={classes.leftGrid}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.btnRoot}
          >
            Know more
          </Button>
        </Grid>
      </Grid>
      <div className={classes.appMarketBtnContainer}>
        <Button
          // variant="contained"
          // color="primary"
          size="large"
          classes={{ root: classes.btnRoot }}
          startIcon={<Apple />}
        >
          App Store
        </Button>
        <Button
          // variant="contained"
          // color="secondary"
          size="large"
          classes={{ root: classes.btnRoot }}
          startIcon={<GooglePlay />}
        >
          Play Store
        </Button>
      </div>
    </Container>
  );
};

export default React.memo(BusinessAssociateProgram);
