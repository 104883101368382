import React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { SectionTitle } from "../../../components";

const SectionStep: React.FC<SectionStepItem> = (props) => {
  const { stepNum, title, subtitle, substeps } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.stepContainer}>
          <Typography className={classes.stepText}>Step</Typography>
          <Typography className={classes.stepNumText}>{stepNum}</Typography>
        </div>
        <div>
          <SectionTitle color="error" align="center">
            {title}
          </SectionTitle>
          <Typography className={classes.subtitle}>{subtitle}</Typography>
        </div>
      </div>
      <div className={classes.substepListContainer}>
        {substeps.map((substep) => (
          <div key={substep.label} className={classes.substepContainer}>
            <div className={classes.imageContainer}></div>
            <Typography align="center" className={classes.substepText}>
              {substep.label}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(SectionStep);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(5),
    },
    header: {
      display: "flex",
      alignItems: "center",
    },
    stepContainer: {
      height: 80,
      width: 80,
      minWidth: 80,
      borderRadius: 80 / 2,
      backgroundColor: theme.palette.secondary.main,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginRight: theme.spacing(3),
    },
    stepText: {
      fontSize: theme.typography.pxToRem(22),
      lineHeight: theme.typography.pxToRem(27),
    },
    stepNumText: {
      fontSize: theme.typography.pxToRem(30),
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: theme.typography.pxToRem(36),
    },
    subtitle: { fontSize: theme.typography.pxToRem(20) },
    substepListContainer: {
      display: "flex",
      justifyContent: "space-between",
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 10,
      padding: theme.spacing(0, 12),
      margin: theme.spacing(2, 0),
    },
    substepContainer: {},
    imageContainer: {
      width: 169,
      height: 100,
      boxShadow: "0px 8px 12px #060220",
      border: `1px solid ${theme.palette.error.main}`,
      margin: theme.spacing(1),
    },
    substepText: {
      fontSize: theme.typography.pxToRem(24),
    },
  })
);
