const API_ROOT = process.env.REACT_APP_API_ROOT;

type anyPlainObj = { [key: string]: string };

interface Ioptions {
  endpoint: string;
  method?: "GET" | "POST" | "PUT" | "PATCH";
  token?: string;
  body?: AnyObject | FormData;
  headers?: anyPlainObj;
  params?: anyPlainObj;
}

function getFullUrl(endpoint: string, params: anyPlainObj): string {
  if (endpoint.startsWith("/")) endpoint = endpoint.slice(1);
  const paramsStr = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
  if (paramsStr) return API_ROOT + endpoint + "?" + paramsStr;
  return API_ROOT + endpoint;
}

function getBody(data?: AnyObject | FormData): string | FormData | undefined {
  if (!data) return;
  if (data instanceof FormData) return data;
  return JSON.stringify(data);
}

function getHeaders(
  customHeaders: anyPlainObj,
  token: string | null,
  body?: string | FormData
): anyPlainObj {
  const headers: anyPlainObj = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (body instanceof FormData) delete headers["Content-Type"];
  if (token) headers.Authorization = `Bearer ${token}`;
  return { ...headers, ...customHeaders };
}

function hasError(
  response: Response,
  jsonResponse: { [key: string]: any }
): boolean {
  if (!response.ok) return true;
  if (response.status >= 400 && response.status < 600) return true;
  // if (!jsonResponse.status) return true;
  if (jsonResponse.code >= 400 && jsonResponse.code < 600) return true;
  return false;
}

export default async function callApi<T extends object = { [x: string]: any }>(
  options: Ioptions
): Promise<[T, boolean]> {
  const {
    endpoint,
    method = "GET",
    body: data,
    token = null,
    headers: customHeaders = {},
    params = {},
  } = options;
  const url = getFullUrl(endpoint, params);
  const body = getBody(data);
  const headers = getHeaders(customHeaders, token, body);
  try {
    const response = await fetch(url, { method, headers, body });
    const jsonResponse = await response.json();
    if (hasError(response, jsonResponse))
      return [jsonResponse.error ?? jsonResponse, true];
    return [jsonResponse, false];
  } catch (err) {
    return [{ msg: String(err) }, true] as [T, true];
  }
}
