import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";

import Hero from "./Hero";
import MainSection from "./MainSection";
import WhatIsCrypto from "./WhatIsCrypto";
import ReferralProgram from "./ReferralProgram";
import GameOfSkill from "./GameOfSkill";
import CheckOurBlogs from "./CheckOurBlogs";
import BusinessAssociateProgram from "./BusinessAssociateProgram";
import { Footer } from "../../components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#060220",
      minHeight: "100vh",
    },
  })
);

const Landing: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hero />
      <MainSection />
      <WhatIsCrypto />
      <ReferralProgram />
      <GameOfSkill />
      <CheckOurBlogs />
      <BusinessAssociateProgram />
      <Footer />
    </div>
  );
};

export default Landing;
