import React from "react";
import { makeStyles, createStyles, Theme, Container } from "@material-ui/core";

import { AgreementSection, Footer } from "../../components";
import Hero from "./Hero";

import tnc from "./tou.json";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#060220",
      minHeight: "100vh",
    },
    container: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  })
);

const TermsOfUsage: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hero />
      <Container className={classes.container}>
        {tnc.map((x) => (
          <AgreementSection key={x.title} section={x} />
        ))}
      </Container>
      <Footer />
    </div>
  );
};

export default TermsOfUsage;
