import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";

import { Footer } from "../../components";
import Hero from './Hero';
import OurTeam from './OurTeam';
import Founders from './Founders';
import Leaders from './Leaders';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#060220",
      minHeight: "100vh",
    },
  })
);

const Team: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hero />
      <OurTeam />
      <Founders />
      <Leaders />
      <Footer />
    </div>
  );
};

export default React.memo(Team);
