import React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Place as PlaceIcon } from "@material-ui/icons";

import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Discord as DiscordIcon,
  Telegram as TelegramIcon,
} from "../../assets/Icons";

const GetInTouch: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.titleText}>Get in touch</Typography>
      {/* Address */}
      <div className={classes.textContainer}>
        <PlaceIcon color="error" className={classes.icon} fontSize="large" />
        <Typography className={classes.text}>
          2 Kallang Avenue, #09-02, CT Hub, Singapore 339407
        </Typography>
      </div>
      {/* FB */}
      <a
        href="https://www.facebook.com/PlayCTLOfficial"
        target="_blank"
        rel="noreferrer"
        className={classes.link}
      >
        <div className={classes.textContainer}>
          <FacebookIcon
            color="error"
            className={classes.icon}
            fontSize="large"
          />
          <Typography className={classes.text}>@playctlOfficial</Typography>
        </div>
      </a>
      {/* Twitter */}
      <a
        href="https://twitter.com/playctlofficial"
        target="_blank"
        rel="noreferrer"
        className={classes.link}
      >
        <div className={classes.textContainer}>
          <TwitterIcon
            color="error"
            className={classes.icon}
            fontSize="large"
          />
          <Typography className={classes.text}>@playctlOfficial</Typography>
        </div>
      </a>
      {/* Telegram */}
      <a
        href="https://t.me/PlayCTL"
        target="_blank"
        rel="noreferrer"
        className={classes.link}
      >
        <div className={classes.textContainer}>
          <TelegramIcon
            color="error"
            className={classes.icon}
            fontSize="large"
          />
          <Typography className={classes.text}>@playctlOfficial</Typography>
        </div>
      </a>
      {/* Discord */}
      <div className={classes.textContainer}>
        <DiscordIcon color="error" className={classes.icon} fontSize="large" />
        <Typography className={classes.text}>@playctlOfficial</Typography>
      </div>
    </div>
  );
};

export default React.memo(GetInTouch);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(4) },
      [theme.breakpoints.up("md")]: { paddingLeft: theme.spacing(12) },
    },
    titleText: {
      fontSize: theme.typography.pxToRem(24),
      marginBottom: theme.spacing(4),
    },
    textContainer: {
      display: "flex",
      margin: theme.spacing(5, 0),
      alignItems: "center",
    },
    icon: { marginRight: theme.spacing(2) },
    text: {
      fontSize: theme.typography.pxToRem(18),
    },
    link: {
      textDecoration: "none",
      color: "inherit",
    },
  })
);
